import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
})
export class ButtonComponent {
    @Input() loading?: boolean;
    @Input() isActive?: boolean;
    @Input() classes?: string[];
    @Input() text?: string;
    @Input() type?: string;

    @Output() onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    onButtonClick(): void {
        this.onClick.emit(true);
    }
}
