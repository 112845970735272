<button
    class="btn"
    [class]="classes"
    [class.loader]="loading"
    [class.active]="isActive"
    [attr.type]="type ? type : null"
    [attr.disabled]="loading === true ? '' : null"
    (click)="onButtonClick()">
    {{ text }}
</button>
